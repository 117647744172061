@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  font-family: Poppins-Regular;
}

@font-face {
  font-family: Colon-Mono-Regular;
  src: url(../fonts/Colon_Mono_Regular.ttf);
}

@font-face {
  font-family: arial;
  src: url(../fonts/arial.ttf);
}

@font-face {
  font-family: arial-thin;
  src: url(../fonts/ArialTh.ttf);
}

@font-face {
  font-family: arial-bold;
  src: url(../fonts/ARIBL0.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: font-Times-New-Roman;
  src: url(../fonts/timesnewroman.ttf);
}

.times-new-roman {
  font-family: font-Times-New-Roman;
}

@font-face {
  font-family: font-Times-New-Roman-Bold;
  src: url(../fonts/timesnewromanbold.ttf);
}

.main {
  display: flex;
  overflow: hidden;
  height: 100vh;
}

.Left-side-Content {
  background-color: #27292b;
  width: 25%;
  padding: 20px;
}

.right-side-Content {
  background-color: #000;
  background-image: url("../img/bg.png");
  background-size: 100% 100%;
  overflow-y: scroll;
}

.Left-side-Content-inner {
  width: 80%;
  margin: 0 auto;
}
.Content-Novels {
  text-align: center;
  border-bottom: 2px solid #3e4145;
  padding-bottom: 15px;
}

.Content-Novels h4 {
  color: #feff01;
  font-size: 17px;
  font-weight: 500;
}

.Content-Novels p {
  color: #feff01;
  font-size: 12px;
  font-weight: 400;
}

.Content-Novels svg {
  font-size: 18px;
  color: #cccccc;
  margin-top: 20px;
}

.Content-Nav {
  border-bottom: 2px solid #3e4145;
  padding: 20px 0;
}

.Content-Nav a {
  display: block;
  color: #feff01;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

.Content-Nav a:first-child {
  margin-bottom: 5px;
}

.Author-info h5 {
  color: #feff01;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
}

.Author-info p {
  color: #feff01;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 1.4;
}

.scroll-content {
  width: 80%;
  margin: 0 auto;
}

.sec1-main {
  width: 90%;
  margin: 20px auto;
  margin-top: 80px;
  margin-bottom: 30px;
}

.sec1-main img {
  width: 100%;
}

.btn-main {
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
}

.btn-main a:first-child {
  background-image: url(../img/sec1-btn-1-bg.png);
  height: 70px;
  width: 160px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
  -moz-transform: translateX(10px);
  -ms-transform: translateX(10px);
  -o-transform: translateX(10px);
}

.btn-main a:nth-child(2) {
  background-image: url(../img/sec1-btn-2-bg.png);
  height: 70px;
  width: 130px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  z-index: 9;
}

.btn-main a:last-child {
  background-image: url(../img/sec1-btn-3-bg.png);
  height: 70px;
  width: 160px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  transform: translateX(-11px);
  -webkit-transform: translateX(-11px);
  -moz-transform: translateX(-11px);
  -ms-transform: translateX(-11px);
  -o-transform: translateX(-11px);
}

.sec2 a:first-child {
  background-image: url(../img/sec2-btn-1-bg.png);
}

.sec2 a:nth-child(2) {
  background-image: url(../img/sec2-btn-2-bg.png);
}

.sec2 a:last-child {
  background-image: url(../img/sec2-btn-3-bg.png);
}

.sec3 a:first-child {
  background-image: url(../img/sec3-btn-1-bg.png);
}

.sec3 a:nth-child(2) {
  background-image: url(../img/sec3-btn-2-bg.png);
}

.sec3 a:last-child {
  background-image: url(../img/sec3-btn-3-bg.png);
}

.sec4 a:first-child {
  background-image: url(../img/sec4-btn-1-bg.png);
}

.sec4 a:nth-child(2) {
  background-image: url(../img/sec4-btn-2-bg.png);
}

.sec4 a:last-child {
  background-image: url(../img/sec4-btn-3-bg.png);
}

.sec5 a:first-child {
  background-image: url(../img/sec5-btn-1-bg.png);
}

.sec5 a:nth-child(2) {
  background-image: url(../img/sec5-btn-2-bg.png);
}

.sec5 a:last-child {
  background-image: url(../img/sec5-btn-3-bg.png);
}

.sec6 a:first-child {
  background-image: url(../img/sec6-btn-1-bg.png);
}

.sec6 a:nth-child(2) {
  background-image: url(../img/sec6-btn-2-bg.png);
}

.sec6 a:last-child {
  background-image: url(../img/sec6-btn-3-bg.png);
}

/* //////////////////modal css///////////////////// */
.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.493);
}

.modal-content {
  /*background-image: linear-gradient(#2AA9A0,#000000);
  */background-image: url(../img/modal-bg.png);
  margin: 10% auto;
  margin-bottom: 0;
  padding: 10px;
  width: 40%;
  border-radius: 20px;
  text-align: center;
  position: relative;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.modal-content-inner {
  background-color: #ffffff62;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
}

.modal-content h3 {
  color: #1dbdc5;
  font-size: 30px;
  font-weight: 700;
  font-family: font-Times-New-Roman-Bold;
}

.modal-content p {
  font-size: 14px;
  font-weight: 400;
  color: #d2c7d2;
  font-family: Poppins-Regular;
}

.modal-content-sec-para {
  font-family: font-Times-New-Roman !important;
}

.audio-liyar {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.audio-liyar img {
  width: 70%;
}

.circle-back {
  border: 2px solid #ffff;
  color: #fff;
  height: 10px;
  width: 10px;
  padding: 5px;
  border-radius: 100px;
  font-size: 12px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.modal-content2 {
  background-image: url(../img/modal-content2.png);
}

.modal-content2 h3 {
  color: #c21414;
}

.modal-content3 {
  background-image: url(../img/pink-bg.png);
}

.modal-content3 h3 {
  color: #c62dae;
}

.modal-content4 h3 {
  color: #cd8a32;
}

.modal-content4 {
  background-image: url(../img/modal4-bg.png);
}

.modal-content5 h3 {
  color: #a5260d;
}

.modal-content5 {
  background-image: url(../img/modal5-bg.png);
}

.modal-content6 h3 {
  color: #a58123;
}

.modal-content6 {
  background-image: url(../img/modal6-bg.png);
}

.rhap_container {
  background-color: transparent !important;
  box-shadow: initial !important;
}

.rhap_time {
  color: #ffff !important;
  font-weight: 600 !important;
}

.rhap_time svg {
  color: #ffff !important;
}

.rhap_progress-indicator {
  background-color: #ffff !important;
}

.rhap_progress-filled {
  background-color: #fff !important;
}

.rhap_additional-controls svg {
  color: #ffff !important;
}

.rhap_main-controls svg {
  color: #ffff !important;
}

.rhap_volume-controls svg {
  color: #ffff !important;
}

.rhap_volume-indicator {
  background-color: #ffff !important;
}

/* .spartman-btn-main a{
    background-image: url(../img/spartman-btn-bg.png) !important;
} */
.contact-us-head {
  color: #fff !important;
  font-size: 45px !important;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.contact-us-modal input {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  margin-bottom: 1rem;
  font-size: 16px;
  font-weight: 500;
}

.contact-us-modal textarea {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  height: 150px;
}

.Send-btn {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.Send-btn-inner {
  background-image: url(../img/sec1-btn-2-bg.png);
  height: 70px;
  width: 170px;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  z-index: 9;
}
.audio-liyar2 {
  width: 47%;
}
.audio-liyar2-main {
  display: flex;
  justify-content: space-between;
}
.audio-liyar2 h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  /* color: #ffff; */
}
.btn-download {
  font-size: 14px;
  font-weight: 600;
  background-color: #ffff;
  padding: 7px 20px;
  border-radius: 5px;
}

/* //////////////////modal css///////////////////// */
@media only screen and (max-width: 1536px) {
  .Content-Nav a {
    font-size: 12px;
  }

  .contact-us-head {
    font-size: 40px !important;
    margin-bottom: 2rem;
  }

  .Send-btn {
    margin: 1rem 0;
  }

  .btn-main {
    margin-left: 1rem;
  }

  .modal-content {
    margin: 8% auto;
  }

  .Author-info p {
    font-size: 12px;
  }

  .Author-info h5 {
    margin: 10px 0;
  }

  .Content-Nav {
    padding: 10px 0;
  }

  .Content-Novels {
    padding-bottom: 5px;
  }

  .Content-Novels h4 {
    font-size: 14px;
  }

  .btn-main a {
    font-size: 12px !important;
    height: 60px !important;
    width: 150px !important;
  }

  .btn-main a:nth-child(2) {
    width: 120px !important;
  }

  .contact-us-modal textarea {
    height: 130px;
  }
  .btn-download {
    font-size: 12px;
    padding: 5px 15px;
  }
}

@media only screen and (max-width: 1366px) {
  .btn-main a {
    font-size: 12px !important;
    height: 50px !important;
    width: 130px !important;
    font-size: 11px !important;
  }
  .btn-download {
    font-size: 11px;
  }
  .contact-us-head {
    font-size: 30px !important;
    margin-bottom: 1rem;
  }

  .contact-us-modal input {
    padding: 7px;
  }

  .contact-us-modal textarea {
    padding: 7px;
    height: 110px;
  }

  /* .btn-main{
        margin-left: -3rem;
    } */
  .btn-main a:nth-child(2) {
    width: 100px !important;
  }

  .Author-info p {
    font-size: 10px;
  }

  .Author-info h5 {
    margin: 5px 0;
    font-size: 14px;
  }

  .Content-Nav a {
    font-size: 10px;
  }

  .Content-Novels h4 {
    font-size: 12px;
  }

  .Content-Novels p {
    font-size: 9px;
  }

  .Content-Novels svg {
    font-size: 14px;
    margin-top: 15px;
  }

  .rhap_time {
    font-size: 12px;
  }

  .modal-content h3 {
    font-size: 20px;
  }

  .modal-content p {
    font-size: 10px;
  }

  .rhap_progress-indicator {
    width: 15px;
    height: 15px;
    top: -5px;
  }

  .rhap_volume-controls svg {
    font-size: 18px;
  }

  .rhap_additional-controls svg {
    font-size: 18px;
  }

  .rhap_main-controls svg {
    font-size: 30px;
  }

  .rhap_main-controls-button {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }

  .rhap_volume-container {
    flex: 0 1 70px;
  }

  .circle-back {
    height: 8px;
    width: 8px;
    padding: 2px;
  }

  .Send-btn-inner {
    height: 55px;
    width: 150px;
    font-size: 14px;
  }
}
#progressBar::-webkit-progress-bar {
  background-color: #7a8382;
  border-radius: 50px;
}

#progressBar::-webkit-progress-value {
  background-color: #ffff;
  border-radius: 50px;
}

.write-review-btn-spartman {
  background-image: url("../img/write-review-01.png");
}

.writereview-btn {
  width: 180px;
  height: 60px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}

.bg-gradient-mn {
  background-image: linear-gradient(#2aa9a0, #27292b);
}

.novel-line-gradient {
  background-image: linear-gradient(to right, #20a49b, #26c6bb, #177770);
}
.novel2-line-gradient {
  background-image: linear-gradient(to right, #a42020, #c62626, #701717);
}
.novel3-line-gradient {
  background-image: linear-gradient(to right, #a42695, #c626c6, #701770);
}
.novel4-line-gradient {
  background-image: linear-gradient(to right, #a47f34, #c6a426, #704517);
}
.novel5-line-gradient {
  background-image: linear-gradient(to right, #570a0c, #c62626, #701717);
}
.novel6-line-gradient {
  background-image: linear-gradient(to right, #574510, #c6a426, #704517);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* BTn Hover Effect */
.image-btn-hover img {
  filter: none;
  /*Defaultstate*/
  transition: filter 0.3s ease;
  /* Smooth transition */
  -webkit-transition: filter 0.3s ease;
  -moz-transition: filter 0.3s ease;
  -ms-transition: filter 0.3s ease;
  -o-transition: filter 0.3s ease;
}

.image-btn-hover:hover > img {
  filter: brightness(90%);
  -webkit-filter: brightness(70%);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff2a;
  border-radius: 10px;
}

@media only screen and (max-width: 1024px) {
  .modal-content {
    width: 95%;
  }
  .rhap_additional-controls{
    display: none;
  }
}


